<template>
  <div>
    <v-card loader-height="2" :loading="loading" :disabled="loading">
      <v-card-text>
        <v-row>
          <v-col cols="6" class="border-right">
            <v-card class="shadow-off">
              <v-card-text class="pt-0 px-0 d-flex">
                <v-btn
                  fab
                  color="primary"
                  elevation="0"
                  small
                  class="mr-4"
                  @click="
                    addflag = true;
                    edit = false;
                    modifydata = {};
                  "
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
                <v-text-field
                  label="Search"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
                <v-btn icon @click="refreshData()" class="ml-2"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                >
              </v-card-text>

              <v-list two-line v-if="listdata.length > 0">
                <template v-for="(v, k) in listdata">
                  <v-list-item
                    :key="k"
                    link
                    class="px-0 border-hover left"
                    @click="
                      selectedindex = k;
                      modifydata = { ...v };
                      editflag = false;
                      addflag = false;
                    "
                  >
                    <v-list-item-content class="pl-4">
                      <v-list-item-title>
                        {{ v.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        v.contactemail
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip>{{ designations[v.permission] }}</v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
              <div v-else>
                <v-alert type="info" :value="true" text border="left">
                  Add new User
                </v-alert>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <div v-if="addflag || editflag">
              <div class="pb-4 d-flex title align-center">
                <span v-if="addflag" class="">Add New User</span>
                <span v-else-if="editflag" class="">Edit User Details</span>
                <v-spacer></v-spacer>
              </div>
              <lb-string
                label="Name"
                v-model="modifydata['name']"
                :error="errors['name']"
              />
              <lb-string
                label="Email"
                v-model="modifydata['contactemail']"
                :error="errors['contactemail']"
              />
              <lb-number
                label="Contact No."
                v-model="modifydata['contactno']"
                :error="errors['contactno']"
              />
              <!-- <lb-dropdown
                label="Designation"
                v-model="modifydata['permission']"
                :items="formdata.permission"
                :error="errors['permission']"
                itemtext="name"
                itemvalue="_id"
              /> -->
              <div class="text-center pb-4">
                <v-btn
                  @click="
                    addflag = false;
                    editflag = false;
                    modifydata =
                      listdata.length >= selectedindex + 1 &&
                      listdata.length > 0
                        ? listdata[selectedindex]
                        : {};
                  "
                  text
                  class="mr-2"
                  >Cancle</v-btn
                >
                <v-btn
                  v-if="addflag"
                  color="primary"
                  @click="save()"
                  elevation="0"
                  >Save</v-btn
                >
                <v-btn
                  v-else-if="editflag"
                  color="primary"
                  @click="update()"
                  elevation="0"
                  >Update</v-btn
                >
              </div>
            </div>
            <div v-else>
              <div class="pb-4 d-flex title align-center">
                <span class="">View User Details</span>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  large
                  v-if="
                    listdata.length >= selectedindex + 1 && listdata.length > 0
                  "
                  @click="
                    editflag = true;
                    add = false;
                    errors = {};
                  "
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </div>

              <v-simple-table
                dense
                v-if="
                  listdata.length >= selectedindex + 1 && listdata.length > 0
                "
              >
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Name:</td>
                      <td>{{ modifydata.name }}</td>
                    </tr>
                    <tr>
                      <td>Email:</td>
                      <td>{{ modifydata.contactemail }}</td>
                    </tr>
                    <tr>
                      <td>Mobile:</td>
                      <td>{{ modifydata.contactno }}</td>
                    </tr>
                    <tr>
                      <td>Designation:</td>
                      <td>{{ designations[modifydata.permission] }}</td>
                    </tr>
                    <tr>
                      <td>Status:</td>
                      <td v-if="modifydata.status">
                        <v-chip class="success" small label>Active</v-chip>
                        <v-btn
                          color="error"
                          class="ml-2"
                          small
                          outlined
                          @click="disable(modifydata._id)"
                          >Disable</v-btn
                        >
                      </td>
                      <td v-else>
                        <v-chip class="error" small label>Inactive</v-chip>
                        <v-btn
                          color="success"
                          class="ml-2"
                          small
                          outlined
                          @click="enable(modifydata._id)"
                          >Enable</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else>
                <v-alert type="info" :value="true" text border="left">
                  No details available
                </v-alert>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "user",
  // props: ['props'],
  data: function () {
    return {
      listdata: [],
      formdata: {},
      designations: {},
      loading: false,
      addflag: false,
      editflag: false,
      selectedindex: 0,
      modifydata: {},
      errors: {},
    };
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.errors = {};
      this.addflag = false;
      this.editflag = false;
      this.getData();
    },
    getData() {
      this.loading = true;
      this.axios
        .post("/v2/user/get", { x: 1 })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.listdata = dt.data.data;
            if (Object.keys(this.modifydata).length === 0)
              this.modifydata = {
                ...(this.listdata.length > 0 ? this.listdata[0] : {}),
              };
            return this.axios.post("/v2/user/formselects");
          } else {
            console.log("Error fetching data");
          }
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.formdata = dt.data.data[0] || {};
            if (this.formdata.permission) {

              for (const i of this.formdata.permission) {
                 this.designations[i._id] = i.name;
              }
            }
          } else throw new Error(dt.data.message || "error fetching formdata");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    disable(id) {
      this.loading = true;
      this.axios
        .post("/v2/user/disable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "User Disabled");
            this.modifydata.status = false;
            this.refreshData();
          } else throw new Error(dt.data.message || "Error updating user");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enable(id) {
      this.loading = true;
      this.axios
        .post("/v2/user/enable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "User Enabled");
            this.modifydata.status = true;
            this.refreshData();
          } else throw new Error(dt.data.message || "Error updating user");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      this.loading = true;
      this.axios
        .post("/v2/user/add", { data: [this.modifydata] })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "User Added");
            this.refreshData();
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
             throw new Error(dt.data.message || "Error adding user");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.loading = true;
      if (this.modifydata._id) {
        this.axios
          .post("/v2/user/edit", {
            data: [this.modifydata],
            id: this.modifydata._id,
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.$store.commit("sbSuccess", "User Updated");
              this.refreshData();
            } else {
              this.errors = {};
              if (dt.data.data.length > 0) {
                if (dt.data.data[0].index0)
                  this.errors = dt.data.data[0].index0;
              }
              throw new Error(dt.data.message || "Error updating user");
            }
          })
          .catch((err) => {
            this.$store.commit(
              "sbError",
              err.message || err || "Unknown error!"
            );
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      } else this.$store.commit("sbError", "No ID found to update");
    },
  },
  computed: {},
  watch: {},
};
</script>
