var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"loader-height":"2","loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"border-right",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"shadow-off"},[_c('v-card-text',{staticClass:"pt-0 px-0 d-flex"},[_c('v-btn',{staticClass:"mr-4",attrs:{"fab":"","color":"primary","elevation":"0","small":""},on:{"click":function($event){_vm.addflag = true;
                  _vm.edit = false;
                  _vm.modifydata = {};}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-text-field',{attrs:{"label":"Search","outlined":"","hide-details":"","dense":""}}),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.refreshData()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),(_vm.listdata.length > 0)?_c('v-list',{attrs:{"two-line":""}},[_vm._l((_vm.listdata),function(v,k){return [_c('v-list-item',{key:k,staticClass:"px-0 border-hover left",attrs:{"link":""},on:{"click":function($event){_vm.selectedindex = k;
                    _vm.modifydata = { ...v };
                    _vm.editflag = false;
                    _vm.addflag = false;}}},[_c('v-list-item-content',{staticClass:"pl-4"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(v.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(v.contactemail))])],1),_c('v-list-item-action',[_c('v-chip',[_vm._v(_vm._s(_vm.designations[v.permission]))])],1)],1)]})],2):_c('div',[_c('v-alert',{attrs:{"type":"info","value":true,"text":"","border":"left"}},[_vm._v(" Add new User ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.addflag || _vm.editflag)?_c('div',[_c('div',{staticClass:"pb-4 d-flex title align-center"},[(_vm.addflag)?_c('span',{},[_vm._v("Add New User")]):(_vm.editflag)?_c('span',{},[_vm._v("Edit User Details")]):_vm._e(),_c('v-spacer')],1),_c('lb-string',{attrs:{"label":"Name","error":_vm.errors['name']},model:{value:(_vm.modifydata['name']),callback:function ($$v) {_vm.$set(_vm.modifydata, 'name', $$v)},expression:"modifydata['name']"}}),_c('lb-string',{attrs:{"label":"Email","error":_vm.errors['contactemail']},model:{value:(_vm.modifydata['contactemail']),callback:function ($$v) {_vm.$set(_vm.modifydata, 'contactemail', $$v)},expression:"modifydata['contactemail']"}}),_c('lb-number',{attrs:{"label":"Contact No.","error":_vm.errors['contactno']},model:{value:(_vm.modifydata['contactno']),callback:function ($$v) {_vm.$set(_vm.modifydata, 'contactno', $$v)},expression:"modifydata['contactno']"}}),_c('div',{staticClass:"text-center pb-4"},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":function($event){_vm.addflag = false;
                  _vm.editflag = false;
                  _vm.modifydata =
                    _vm.listdata.length >= _vm.selectedindex + 1 &&
                    _vm.listdata.length > 0
                      ? _vm.listdata[_vm.selectedindex]
                      : {};}}},[_vm._v("Cancle")]),(_vm.addflag)?_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")]):(_vm.editflag)?_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.update()}}},[_vm._v("Update")]):_vm._e()],1)],1):_c('div',[_c('div',{staticClass:"pb-4 d-flex title align-center"},[_c('span',{},[_vm._v("View User Details")]),_c('v-spacer'),(
                  _vm.listdata.length >= _vm.selectedindex + 1 && _vm.listdata.length > 0
                )?_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){_vm.editflag = true;
                  _vm.add = false;
                  _vm.errors = {};}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1),(
                _vm.listdata.length >= _vm.selectedindex + 1 && _vm.listdata.length > 0
              )?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v("Name:")]),_c('td',[_vm._v(_vm._s(_vm.modifydata.name))])]),_c('tr',[_c('td',[_vm._v("Email:")]),_c('td',[_vm._v(_vm._s(_vm.modifydata.contactemail))])]),_c('tr',[_c('td',[_vm._v("Mobile:")]),_c('td',[_vm._v(_vm._s(_vm.modifydata.contactno))])]),_c('tr',[_c('td',[_vm._v("Designation:")]),_c('td',[_vm._v(_vm._s(_vm.designations[_vm.modifydata.permission]))])]),_c('tr',[_c('td',[_vm._v("Status:")]),(_vm.modifydata.status)?_c('td',[_c('v-chip',{staticClass:"success",attrs:{"small":"","label":""}},[_vm._v("Active")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","small":"","outlined":""},on:{"click":function($event){return _vm.disable(_vm.modifydata._id)}}},[_vm._v("Disable")])],1):_c('td',[_c('v-chip',{staticClass:"error",attrs:{"small":"","label":""}},[_vm._v("Inactive")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"success","small":"","outlined":""},on:{"click":function($event){return _vm.enable(_vm.modifydata._id)}}},[_vm._v("Enable")])],1)])])]},proxy:true}],null,false,1492664791)}):_c('div',[_c('v-alert',{attrs:{"type":"info","value":true,"text":"","border":"left"}},[_vm._v(" No details available ")])],1)],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }